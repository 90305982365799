<template>
  <div>
    <a-form
      @submit.prevent="showConfirmModal"
      class="login-form"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 13 }"
      ref="formRef"
    >
      <a-alert
        v-if="errorMessage"
        message="Error"
        description="Semua field input form tidak boleh kosong."
        type="error"
        show-icon
        style="margin-bottom: 16px"
      />
      <a-form-item
        ref="name"
        label="Name"
        name="name"
        :validate-status="errors.name ? 'error' : ''"
        :help="errors.name"
      >
        <a-input v-model:value="form.name" placeholder="Name" />
      </a-form-item>
      <a-form-item
        ref="nip"
        label="NIP"
        name="nip"
        :validate-status="errors.nip ? 'error' : ''"
        :help="errors.nip"
      >
        <a-input v-model:value="form.nip" placeholder="NIP" />
      </a-form-item>
      <a-form-item
        ref="email"
        label="Email"
        name="email"
        :validate-status="errors.email ? 'error' : ''"
        :help="errors.email"
      >
        <a-input v-model:value="form.email" placeholder="Email" />
      </a-form-item>
      <a-form-item
        ref="whatsapp"
        label="Whatsapp"
        name="whatsapp"
        :validate-status="errors.whatsapp ? 'error' : ''"
        :help="errors.whatsapp"
      >
        <a-input v-model:value="form.whatsapp" placeholder="Whatsapp" />
      </a-form-item>
      <a-form-item
        ref="instansi"
        label="Instansi"
        name="instansi"
        :validate-status="errors.instansi ? 'error' : ''"
        :help="errors.instansi"
      >
        <a-select
          v-model:value="form.instansi"
          placeholder="Select Instansi"
          show-search
          :filter-option="false"
          @search="handleSearchInstansi"
        >
          <a-select-option
            v-for="instansi in filteredInstansiList"
            :key="instansi.id"
            :value="instansi.id"
          >
            {{ instansi.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        ref="jadwal"
        label="Pilih Tanggal"
        name="jadwal"
        :validate-status="errors.jadwal ? 'error' : ''"
        :help="errors.jadwal"
      >
        <a-select
          v-model:value="form.jadwal"
          placeholder="Select Jadwal"
          :options="jadwalOptions"
        >
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit">{{
          isEditing ? "Update" : "Submit"
        }}</a-button>
      </a-form-item>
    </a-form>

    <a-modal
      title="Konfirmasi Penyimpanan"
      v-model:visible="confirmVisible"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <p>
        Perhatian: Data yang akan Anda simpan tidak dapat diubah lagi. Hanya nama instansi dan tanggal yang akan ditampilkan setelah penyimpanan. Anda hanya memiliki satu kesempatan untuk memilih. Pastikan semua data sudah benar sebelum melanjutkan. Apakah Anda yakin ingin menyimpan dan mengirim data booking integrasi ini? Instansi yang sudah disubmit tidak akan muncul lagi dalam pilihan instansi.
      </p>
    </a-modal>

    <a-row type="flex" justify="space-between" style="margin-bottom: 16px">
      <a-col>
        <a-select
          v-model="selectedInstansifilter"
          placeholder="Filter by Instansi"
          show-search
          :filter-option="false"
          @search="handleSearchInstansiFilter"
          @change="handleInstansiFilterChange"
          style="width: 200px"
          allowClear="true"
        >
          <a-select-option
            v-for="instansi in filteredInstansiFilterList"
            :key="instansi.id"
            :value="instansi.id"
          >
            {{ instansi.name }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col>
        <a-select
          v-model:value="pagination.pageSize"
          @change="handlePageSizeChange"
          style="width: 120px"
          allowClear="true"
        >
          <a-select-option value="5">5</a-select-option>
          <a-select-option value="10">10</a-select-option>
          <a-select-option value="20">20</a-select-option>
          <a-select-option value="50">50</a-select-option>
          <a-select-option value="100">100</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-spin size="large" :spinning="loading">
      <a-table
        :columns="columns"
        :dataSource="users"
        :pagination="{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          showQuickJumper: true,
        }"
        rowKey="id"
        @change="handleTableChange"
      >
        <template #footer>
          <div style="text-align: right;">
            <span>Total Pages: {{ totalPages }}</span> - 
            <span>Total Data: {{ pagination.total }}</span>
          </div>
        </template>
      </a-table>
    </a-spin>
  </div>
</template>
<script>
import axios from "axios";
import { ref, reactive, toRaw, onMounted, watch } from "vue";
import { message } from "ant-design-vue";
import moment from "moment";
import "moment/locale/id"; // Import locale Indonesia

moment.locale("id"); // Set locale to Indonesia

const apiUrl = process.env.VUE_APP_API_URL;

export default {
  setup() {
    const formRef = ref(null);
    const form = reactive({
      id: null,
      name: "",
      nip: "",
      email: "",
      whatsapp: "",
      instansi: null,
      jadwal: null,
    });
    const confirmVisible = ref(false);
    const errors = reactive({
      name: "",
      nip: "",
      email: "",
      whatsapp: "",
      instansi: "",
      jadwal: "",
    });
    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    };
    const isValidWhatsAppNumber = (number) => {
      const regex = /^62\d{8,11}$/;
      return regex.test(number);
    };
    const isValidNIP = (nip) => {
      const regex = /^\d{10,18}$/;
      return regex.test(nip);
    };
    const rules = {
      name: [{ required: true, message: "Please input Name", trigger: "change" }],
      nip: [{ required: true, message: "Please input NIP", trigger: "change" }],
      email: [{ required: true, message: "Please input Email", trigger: "change" }],
      whatsapp: [{ required: true, message: "Please input Whatsapp", trigger: "change" }],
      instansi: [
        { required: true, message: "Please select Instansi", trigger: "change" },
      ],
      jadwal: [{ required: true, message: "Please select Jadwal", trigger: "change" }],
    };
    const users = ref([]);
    const search = ref("");
    const selectedInstansifilter = ref(null); // Selected Instansi for filtering
    const filteredInstansiFilterList = ref([]);
    const pagination = reactive({
      current: 1,
      pageSize: 10, // Set default page size to 5
      total: 0,
    });
    const instansiList = ref([]);
    const instansifilterList = ref([]);
    const jadwalOptions = ref([]);
    const isEditing = ref(false);
    const errorMessage = ref(false);
    const loading = ref(false);
    const totalPages = ref(0);

    const showConfirmModal = () => {
      confirmVisible.value = true;
    };

    const handleCancel = () => {
      confirmVisible.value = false;
    };

    const handleSubmit = async () => {
      confirmVisible.value = false; // Hide the modal

      try {
        // Reset errors
        Object.keys(errors).forEach((key) => (errors[key] = ""));

        if (!form.name) errors.name = "Nama wajib diisi";
        if (!form.nip) {
          errors.nip = "NIP wajib diisi";
        } else if (!isValidNIP(form.nip)) {
          errors.nip = "NIP harus berupa angka dan memiliki panjang maksimal 18 digit";
        }
        if (!form.email) {
          errors.email = "Email wajib diisi";
        } else if (!validateEmail(form.email)) {
          errors.email = "Email tidak valid";
        } else if (form.email.length > 85) {
          errors.email = "Email tidak boleh lebih dari 85 karakter";
        }
        if (!form.whatsapp) {
          errors.whatsapp = "Whatsapp wajib diisi";
        } else if (!isValidWhatsAppNumber(form.whatsapp)) {
          errors.whatsapp = "Nomor WhatsApp tidak valid. Nomor harus dimulai dengan 62.";
        }
        if (!form.instansi) errors.instansi = "Instansi wajib dipilih";
        if (!form.jadwal) errors.jadwal = "Jadwal wajib dipilih";

        if (Object.values(errors).some((err) => err)) {
          return;
        }

        errorMessage.value = false;

        if (isEditing.value) {
          const response = await axios.put(
            `${apiUrl}/api/users/${form.id}`,
            toRaw(form)
          );
          console.log(response.data);
          message.success("Data berhasil diperbarui");
          fetchUsers();
          fetchInstansi();
          fetchJadwal();
          resetForm();
        } else {
          const response = await axios.post(
            `${apiUrl}/api/submit`,
            toRaw(form)
          );
          console.log(response.data);
          await bookJadwal(form.jadwal);
          message.success("Data berhasil ditambahkan");
          fetchUsers();
          fetchInstansi();
          fetchJadwal();
          resetForm();
        }
      } catch (error) {
        console.log("Validation failed:", error);
        errorMessage.value = true;
      }
    };

    const bookJadwal = async (jadwalId) => {
      try {
        await axios.post(
          `${apiUrl}/api/jadwal/${jadwalId}/book`,
          {},
          // {
          //   headers: {
          //     Authorization: `Bearer ${localStorage.getItem("token")}`,
          //   },
          // }
        );
        fetchJadwal();
      } catch (error) {
        console.error("Failed to book jadwal:", error);
      }
    };

    const fetchJadwal = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/jadwal`, {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("token")}`,
          // },
        });
        jadwalOptions.value = response.data.map((j) => ({
          value: j.tanggal,
          label: moment(j.tanggal).format("dddd, DD MMMM YYYY"),
        }));
      } catch (error) {
        console.error(error);
      }
    };

    const fetchUsers = async (paginationData = pagination, sorter = {}) => {
      loading.value = true;
      const params = {
        page: paginationData.current,
        pageSize: paginationData.pageSize,
        search: search.value,
        instansi: selectedInstansifilter.value, // Add instansi to params
        sortField: sorter.field || "id",
        sortOrder: sorter.order || "descend",
      };
      try {
        const response = await axios.get(`${apiUrl}/api/userslist`, {
          params,
        });
        users.value = response.data;
        pagination.total = parseInt(response.headers["x-total-count"]);
        totalPages.value = Math.ceil(pagination.total / pagination.pageSize);
      } catch (error) {
        console.error(error);
      } finally {
        loading.value = false;
      }
    };

    const fetchInstansi = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/instansi`);
        instansiList.value = response.data;
        filteredInstansiFilterList.value = response.data;
      } catch (error) {
        console.error(error);
      }
    };

    const fetchInstansifilter = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/instansifilter`);
        instansifilterList.value = response.data;
        filteredInstansiFilterList.value = response.data;
      } catch (error) {
        console.error(error);
      }
    };

    const handleTableChange = (paginationData, filters, sorter) => {
      pagination.current = paginationData.current;
      fetchUsers(paginationData, sorter);
    };

    const handlePageSizeChange = (pageSize) => {
      pagination.pageSize = pageSize;
      pagination.current = 1;
      fetchUsers();
    };

    const handleDelete = async (id) => {
      try {
        const response = await axios.delete(`${apiUrl}/api/users/${id}`);
        console.log(response.data);
        message.success("Data berhasil dihapus");
        fetchUsers();
        fetchInstansi();
        fetchJadwal();
      } catch (error) {
        console.error(error);
      }
    };

    const handleEdit = (record) => {
      isEditing.value = true;
      form.id = record.id;
      form.name = record.name;
      form.nip = record.nip;
      form.email = record.email;
      form.whatsapp = record.whatsapp;
      form.instansi = record.instansi_id;
      form.jadwal = record.jadwal_id;
    };

    const resetForm = () => {
      isEditing.value = false;
      form.id = null;
      form.name = "";
      form.nip = "";
      form.email = "";
      form.whatsapp = "";
      form.instansi = null;
      form.jadwal = null;
    };

    const filterInstansi = (input, option) => {
      return option.children.toString().toLowerCase().includes(input.toLowerCase());
    };

    const handleInstansiFilterChange = (value) => {
      selectedInstansifilter.value = value;
      fetchUsers();
    };

    const columns = [
      {
        title: "Instansi",
        dataIndex: "instansi_name",
        key: "instansi_name",
        sorter: true,
      },
      {
        title: "Jadwal",
        dataIndex: "tanggal",
        key: "tanggal",
        sorter: true,
        customRender: ({ text }) => moment(text).format("dddd, DD MMMM YYYY"),
      },
    ];

    const handleSearchInstansi = (value) => {
      if (value.length < 3) {
        filteredInstansiList.value = instansiList.value;
      } else {
        filteredInstansiList.value = instansiList.value.filter(instansi =>
          instansi.name.toLowerCase().includes(value.toLowerCase())
        );
      }
    };

    const handleSearchInstansiFilter = (value) => {
      if (value.length < 3) {
        filteredInstansiFilterList.value = instansifilterList.value;
      } else {
        filteredInstansiFilterList.value = instansifilterList.value.filter(instansi =>
          instansi.name.toLowerCase().includes(value.toLowerCase())
        );
      }
    };

    const filteredInstansiList = ref([]);
    watch(instansiList, (newList) => {
      filteredInstansiList.value = newList;
    });

    watch(instansifilterList, (newList) => {
      filteredInstansiFilterList.value = newList;
    });

    onMounted(() => {
      fetchUsers();
      fetchInstansi();
      fetchInstansifilter();
      fetchJadwal();
      filteredInstansiList.value = instansiList.value; // Initialize filtered list
      filteredInstansiFilterList.value = instansifilterList.value; // Initialize filtered list
    });

    return {
      formRef,
      form,
      rules,
      errors,
      users,
      search,
      selectedInstansifilter, // Add this to the returned object
      pagination,
      instansiList,
      instansifilterList,
      filteredInstansiFilterList,
      isEditing,
      jadwalOptions,
      errorMessage,
      loading,
      showConfirmModal,
      handleSubmit,
      handleCancel,
      fetchUsers,
      fetchInstansi,
      fetchInstansifilter,
      fetchJadwal,
      handleTableChange,
      handlePageSizeChange,
      handleDelete,
      handleEdit,
      resetForm,
      filterInstansi,
      handleInstansiFilterChange, // Add this to the returned object
      columns,
      confirmVisible,
      handleSearchInstansi,
      handleSearchInstansiFilter,
      filteredInstansiList, // Add this to the returned object
      totalPages, // Add this to the returned object
    };
  },
};
</script>

<style>
/* Tambahkan style Anda di sini jika perlu */
</style>